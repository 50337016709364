import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class CatService {
    private _targetAudience: string = "Default";
    get targetAudience(): string {
        return this._targetAudience;
    }

    set targetAudience(targetAudience: string) {
        this._targetAudience = targetAudience;
        this.load();
    }
    
    private _locale: string = "en";
    getLocale(): string {
        return this._locale;
    }

    setLocale(locale: string): Promise<boolean> {
        this._locale = locale;
        return this.load();
    }
    
    public data: any = null;


    constructor(private http: HttpClient){}

    load(): Promise<boolean> {
        return this.http.get<any>(`./assets/cat_resources/CloudTrayWeb_${this.targetAudience}.${this.getLocale()}.json`)
        .toPromise()
        .then(data => {
            let kvPair = [];
            data.forEach(element => {
                kvPair[element.StringKey] = element.StringText;
            });
            this.data = kvPair;
            return Promise.resolve(true);
        })
        .catch(err=>{
            return Promise.resolve(false);
        });        
    }

    getString(key: string): string {
        let string = this.data[key] || null;
        return string;
    }
}
